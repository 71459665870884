import React from "react";

import { useAtom } from "jotai";
import { Navigate } from "react-router-dom";
import { redirectAtom, userAtom } from "../../atoms";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const [user] = useAtom(userAtom);
  const [, setRedirect] = useAtom(redirectAtom);
  setRedirect(`${window.location.pathname}${window.location.search}`);
  if (user == null) {
    return <Navigate to="/login" />;
  }
  if (user.role === "UnverifiedUser") {
    return <Navigate to="/verify" />;
  }
  const excludedUsersFromNDAFlow = ['kevinariasf@hotmail.com', 'zach@getcharta.com', 'kevin@getcharta.com', 'ty@colture.cc', 'wealthy@coltureholdings.com', 'jennair@coltureholdings.com', 'pk@coltureholdings.com'];
  const currentPath = `${window.location.pathname}${window.location.search}`;
  const ndaContractPath = `/contract/${user.NDAContractId}`;
  const preStepThroughPath = '/pre-step-through?contract=64f7538735e735e2a052a18a&industry=music&role=contractor';

  if (!excludedUsersFromNDAFlow.includes(user.email) && user.completed <= 0 && currentPath !== ndaContractPath && window.location.pathname.split('/')[1] !== 'step-through') {

    const targetPath = user.needOtherResponse >= 1 ? ndaContractPath : preStepThroughPath;

    if (currentPath !== targetPath) {
      return <Navigate to={targetPath} />;
    }
  }
  
  return children;
};

export default ProtectedRoute;
