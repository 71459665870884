import {
  chakra,
  FlexProps,
  Flex,
  Box,
  Text,
  Heading,
  useColorModeValue
} from '@chakra-ui/react';
import React from 'react';
import ChartaAccented from './ChartaAccented';
import { loadingScreenTheme } from 'src/theme/theme';
import ChartaDark from './ChartaDark';

export interface LoadScreenProps extends FlexProps {
  label?: string;
}

export const LoadScreen: React.FC<LoadScreenProps> = ({ label, ...props }) => {
  const bg = loadingScreenTheme.background;

  return (
    <Flex
      width="100vw"
      height="100vh"
      background={bg}
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexDir="column" alignItems="center">
        <Box w={{ base: '92px', lg: '200px' }} h={{ base: '24px', lg: '50px' }} marginBottom={'5'}>
          <ChartaDark />
        </Box>
        <Heading
          fontSize="24px"
          mt="16px"
          color={loadingScreenTheme.headingTextColor}
        >
          <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Heading>
      </Flex>
    </Flex>
  );
};
